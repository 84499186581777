import { SiteInit } from './assets/siteinit';
import { SA } from './assets/scrollAnimation';
import { CSSAcdn } from './assets/accordion';

/*
SiteInit option default
{
	menuBtn: '#menuBtn', //メニューボタンのセレクタ
	menu: '#menu', //メニューのセレクタ
	menuBtnSwitch: 'on', //メニューを開く時にメニューボタンに追加するクラス
	menuSwitch: 'show', //メニューを開く時にメニューに追加するクラス
	anchorIgnore: '.noscroll', //スムーススクロールを適用しない要素のセレクタ
	anchorSpeed: 300, //スムーススクロールのスピード
	anchorCallback: null, //スムーススクロール時に実行するコールバック関数
}
メソッド
siteInit.anchorScroll(href, cb); // hrefまでスムーススクロールさせる。hrefはセレクタorDOMオブジェクト cbはコールバック
siteInit.menuToggle() // メニューの開閉を切り替える（メニューボタンとメニューのクラスを切り替える）
siteInit.menuOpen() // メニューを開く（メニューボタンとメニューに開く時用のクラスをセットする）
siteInit.menuClose() // メニューを閉じる（メニューボタンとメニューの開く時用のクラスを削除する）
*/

const siteInit = new SiteInit({
	anchorCallback: function () {
		siteInit.menuClose(); //スムーススクロール時にメニューを閉じる処理
	}
});


/*
ScrollAnimation option default
{
	selector: '.anm', //スクロールアニメーションを適用する要素のセレクタ
	margin: window.innerHeight * 0.2, //スクロールアニメーションを適用する要素を表示させる位置（デフォルトは画面の一番下から20%の位置）
	showClass: 'show', //スクロールアニメーションを適用する要素が表示位置に来た時に追加するクラス
}
*/

const sa = new SA();


/*
CSSAcdn option default
{
	switchClass: 'open', //開く時に追加するクラス名
}
*/

const acdn = new CSSAcdn();
const btns = document.querySelectorAll('.acdnButton');
const closeBtns = document.querySelectorAll('.acdnClose');
const acdnContents = document.querySelectorAll('.acdnContents');
const closeAll = () => {
	for (let i = 0; i < acdnContents.length; i++) {
		acdn.close(acdnContents[i]);
	}
};

for (let i = 0; i < acdnContents.length; i++) {
	acdnContents[i].classList.add('acdn-active');
}

for (let i = 0; i < btns.length; i++) {
	btns[i].addEventListener('click', (e) => {
		closeAll();
		let target = e.currentTarget.nextElementSibling;
		e.currentTarget.classList.toggle('active');
		acdn.toggle(target);
	}, false);
}

for (let i = 0; i < closeBtns.length; i++) {
	closeBtns[i].addEventListener('click', (e) => {
		let target = e.currentTarget.parentNode.parentNode.parentNode;
		acdn.close(target);
	}, false);
}

(function () {

	//formタグにクラス追加
	const form = document.querySelector(".wpcf7-form");
	if (!form) {
		return false;
	}
	form.classList.add("hide_error_message");

	//送信または確認ボタンをクリックしたとき
	const formBtns = document.querySelectorAll(".wpcf7-confirm, .wpcf7-submit");
	if (formBtns.length === 0) {
		return false;
	}
	formBtns.forEach(function (elm) {
		elm.addEventListener("click", () => {
			form.classList.remove("hide_error_message");
		});
	});

})();

//LINEボタン
(function () {

	const toTopBtn = document.getElementById('toTop');
	if (!toTopBtn) {
		return false;
	}

	const footer = document.querySelector('footer'); // footer要素を取得
	if (!footer) {
		console.error('Footer element not found.');
		return;
	}

	const cloneElms = {
		fix: toTopBtn.cloneNode(true),
		static: toTopBtn.cloneNode(true),
	}
	cloneElms.fix.id = '';
	cloneElms.static.id = '';
	cloneElms.fix.style.opacity = 0;
	cloneElms.static.style.opacity = 0;
	cloneElms.fix.style.pointerEvents = 'none';
	cloneElms.static.style.pointerEvents = 'none';
	cloneElms.fix.classList.add('fix');

	toTopBtn.insertAdjacentElement('afterend', cloneElms.fix);
	toTopBtn.insertAdjacentElement('afterend', cloneElms.static);

	// IntersectionObserver を設定し、footerの表示状態を監視
	const observer = new IntersectionObserver((entries) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				toTopBtn.classList.add('show'); // footerが画面内に入ったらshowを追加
			} else {
				toTopBtn.classList.remove('show'); // footerが画面外に出たらshowを削除
			}
		});
	}, {
		threshold: 0.1 // 10%のfooterが見えたらトリガー
	});

	observer.observe(footer); // footerの監視を開始

	// 固定表示の管理（既存の機能）
	const scroll = () => {
		if (cloneElms.fix.getBoundingClientRect().top < cloneElms.static.getBoundingClientRect().top) {
			toTopBtn.classList.add('fix');
		} else {
			toTopBtn.classList.remove('fix');
		}
	};

	window.addEventListener('load', scroll);
	window.addEventListener('scroll', scroll);

})();